exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-renovation-gallery-tsx": () => import("./../../../src/pages/renovation-gallery.tsx" /* webpackChunkName: "component---src-pages-renovation-gallery-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-templates-composable-page-tsx": () => import("./../../../src/templates/composable-page.tsx" /* webpackChunkName: "component---src-templates-composable-page-tsx" */),
  "component---src-templates-project-page-tsx": () => import("./../../../src/templates/project-page.tsx" /* webpackChunkName: "component---src-templates-project-page-tsx" */)
}

